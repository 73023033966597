import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>PÁGINA NÃO ENCONTRADA</h1>
    <p>Você acessou uma rota que não esiste, vote a página inicial e tente novamente.</p>
  </Layout>
)

export default NotFoundPage
